import React, { useState, useEffect } from 'react';
import UpdateService from '../../../../services/admin/updates/updateService';
import GetDataDetail from '../../../../services/loaderService';
import LoaderUtilsService from '../../../../services/admin/utils/loaderUtilsService';
import LoaderUtilsService2 from '../../../../services/admin/utils/loaderUtilsService2';
import TooltipModal from '../../../../components/modals/ToolTipModal';
import UpperFormatt from '../../../../components/UpperFormatt';

import { BiCheck } from 'react-icons/bi';
import config from '../../../../config';

const UpdateVehicleData = ({ id }) => {
    const customer = localStorage.getItem('region')
    const [datas, setDatas] = useState([])
    const [categories, setCategories] = useState([])
    const [countries, setCountries] = useState([])
    const [vehicleCode, setVehicleCode] = useState(true)

    const domain = config.api_domain.domain;
    const url = `${domain}/vehicle`;

    useEffect(() => {
        GetDataDetail(setDatas, url, id);
    }, [setDatas, url, id]);

    const [formData, setFormData] = useState({
        name: "",
        fuel: "",
        type: "",
        assurance_code: "",
        state: "",
        id_country: "",
    });

    useEffect(() => {
        if (datas.length > 0) {
            const data = datas[0];
            if (data.country_name === "COLOMBIA"){
                setVehicleCode(false)
            }
            setFormData({
                name: data.name || "",
                fuel: data.fuel || "",
                type: data.type || "",
                code: data.assurance_code || "",
                state: data.state || "",
                id_country: data.id_country || "",
            });
        }
    }, [datas]);

    const [showForm, setShowForm] = useState(false);
    const [showUpdatedText, setShowUpdatedText] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        UpdateService('updateVehicle', id, formData)
            .then(() => {
                setShowForm(false);
                setShowUpdatedText(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenForm = () => {
        setShowForm(true);
        setShowUpdatedText(false);
    };

    const handleState = (e) => {
        const newState = e.target.checked ? "active" : "inactive";
        setFormData((prevFormData) => ({
            ...prevFormData,
            state: newState,
        }));

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataCategories = await LoaderUtilsService('categories');
                const dataCountries = await LoaderUtilsService2('countries');
                setCategories(dataCategories)
                setCountries(dataCountries)
                // console.log(formData, 'Hola')
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const uniqueCategories = Array.from(new Set(categories.map(item => item.category)));

    return (
        <div>
            {showForm ? (
                <>
                    <div>
                        <label className='modal-lavel'>
                            Referencia del vehículo
                            <input
                                type="text"
                                className='form-control'
                                name="name"
                                value={formData.name || ""}
                                onChange={handleChange}
                            />
                            <br />
                        </label>
                        <br />
                        <label className='modal-lavel'>
                            Tipo de vehículo
                            <br />
                            <select className='form-select' name="fuel" value={formData.fuel || ""} onChange={handleChange}>
                                <option value="Eléctrico">Eléctrico</option>
                                <option value="Diesel">Diesel</option>
                                <option value="Gasolina">Gasolina</option>
                            </select>
                            <br />
                        </label>
                        <br />
                        <label className='modal-lavel'>
                            Categoría del Vehículo
                            <br />
                            <select className='form-select' name="type" id='typeSelect' value={formData.type || ""} onChange={handleChange} required>
                                {uniqueCategories.map((cat, index) => (
                                    <option key={index} value={cat}><UpperFormatt string={cat} /></option>
                                ))}
                            </select>
                            <br />
                        </label>
                        {vehicleCode === false ? (
                            <>
                                <br />
                                <label className='modal-lavel'>
                                    Código de aseguramiento SURA <TooltipModal text={'Ver lista de códigos SOAT en ventana administrativa'} title={'Lista de SOAT'} />
                                    <input
                                        type="text"
                                        className='form-control'
                                        name="code"
                                        value={formData.code || ""}
                                        onChange={handleChange}
                                    />
                                    <br />
                                </label>
                            </>
                        ) : (<></>)}
                        <br />
                        <label className='modal-lavel'>
                            País del vehículo
                            <br />
                            <select
                                className='form-select'
                                name="id_country"
                                id='typeSelect'
                                value={formData.id_country || ""}
                                onChange={handleChange}
                                required>

                                {countries.filter(item => item.code !== 'admin').map((c, index) => (
                                    <option key={index} value={c.id}> {c.country_name} </option>
                                ))}
                            </select>
                            <br />
                        </label>
                        <br />
                        <label className='modal-lavel'>
                            Estado
                            <br />
                        </label>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name='state'
                                checked={formData.state === "active" || ""}
                                onChange={handleState}
                            />
                        </div>
                        <br /><br />
                        <button onClick={handleSubmit}>Actualizar Información</button>
                    </div>
                </>
            ) : (
                <>
                    <p>Información actualizada <BiCheck style={{ fontSize: '30px', color: 'green' }} /></p>
                    <button onClick={handleOpenForm}>Editar Información</button>
                </>
            )}
        </div>
    );
};

export default UpdateVehicleData;
