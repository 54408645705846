import React, { useState, useEffect } from "react";

import LoaderUtilsService from "../../../services/admin/utils/loaderUtilsService";
import UpdateService from "../../../services/admin/updates/updateUtils";
import AddUtilsService from "../../../services/admin/adds/AddUtilService";
import DeleteService from "../../../services/admin/deletes/DeleteService";
import LoaderUtilsService2 from "../../../services/admin/utils/loaderUtilsService2";
import Spinner from "../../../components/spinner/Spinner";
import { useParams } from "react-router-dom";
import TooltipModal from "../../../components/modals/ToolTipModal";
import Swal from "sweetalert2";

const ListMaintenances = () => {

  const options = {
    useGrouping: true,
    minimumFractionDigits: 0
  };

  const params = useParams()

  const [loading, setLoading] = useState(true);
  const [maintenances, setMaintenances] = useState([]);
  const [maxValue, setMaxValue] = useState(130000)
  const [vehicleData, setVehicleData] = useState([])
  const [datas, setDatas] = useState([])
  const [minToFill, setMinToFill] = useState([])
  const [increment, setIncrement] = useState(5000)

  const idVehicle = params.id

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const data = {
      "id_vehicle": idVehicle,
      "mileage": 0,
      "price": 1
    };
    await AddUtilsService('insert_maintenance', data);

    try {
      const updatedMaintenances = await LoaderUtilsService(`maintenances/${idVehicle}/${maxValue || 1}`);
      setMaintenances(updatedMaintenances);

    } catch (error) {
      console.error(error);
    }
  };

  const HandleResetMaintenances = async () => {
    try {
      await DeleteService('admin/delete_maintenances', idVehicle)
      console.log("Reset Finished")
    } catch (error) {
      console.error(error)
    }
  }

  const handleEdit = (index) => {
    const updatedmaintenances = [...maintenances];
    updatedmaintenances[index].editMode = true;
    setMaintenances(updatedmaintenances);
  };

  const handleUpdate = async (index) => {
    const updatedmaintenances = [...maintenances];
    const { id, price, mileage } = updatedmaintenances[index];

    try {
      let dataValue = {
        price: price,
        mileage: mileage
      }
      await UpdateService("update_maintenances", id, dataValue);
      updatedmaintenances[index].editMode = false;
      setMaintenances(updatedmaintenances);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMileage = (index, e) => {
    const updatedmaintenances = [...maintenances];
    updatedmaintenances[index].mileage = e.target.value;
    setMaintenances(updatedmaintenances);
  };

  const handlePrice = (index, e) => {
    const updatedmaintenances = [...maintenances];
    updatedmaintenances[index].price = e.target.value;
    setMaintenances(updatedmaintenances);
  };


  const handleCancel = (index) => {
    const updatedmaintenances = [...maintenances];
    updatedmaintenances[index].editMode = false;
    setMaintenances(updatedmaintenances);
  };

  const handleMaxValue = (e) => {
    setMaxValue(e.target.value);
  };

  const handleFillData = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción rellenará los datos. ¿Deseas continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, rellenar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#0064C8',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        fillData();
      }
    });
  }

  const handleResetData = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción eliminará los datos desde los 120.000 km en adelante. ¿Deseas continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, reiniciar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#0064C8',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        HandleResetMaintenances();
      }
    });
  }

  const handleIncrement = (e) => {
    setIncrement(Number(e.target.value))
  }

  const fillData = () => {
    const fetchData = async () => {
      try {
        let currentValue = datas[datas.length - 1].mileage + increment;
        for (let i = 0; i < 29; i++) {
          for (let index = 0; index < datas.length; index++) {
            const rutine = {
              "id_vehicle": idVehicle,
              "mileage": currentValue,
              "price": datas[index].price
            }
            await AddUtilsService('insert_maintenance', rutine);
            console.log('Ingresando ', rutine.mileage, rutine.price)
            currentValue += increment;
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    console.log('Rellenado de datos')
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await LoaderUtilsService(`maintenances/${idVehicle}/${maxValue || 120000}`);
        const datas = await LoaderUtilsService(`maintenances/${idVehicle}/1000000`);
        const vehicle_data = await LoaderUtilsService2(`features/${idVehicle}`);
        setVehicleData(vehicle_data)
        setDatas(datas.slice(-4));
        setMinToFill(datas.slice(-1))

        setMaintenances(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [maxValue]);


  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <h2 className='titleComponent'>Rutinas y precios de mantenimientos preventivos</h2>
      <div className="container shadow-left" style={{ backgroundColor: "white" }}>
        <br />
        <div className="row">
          <div className="row">
            <div className="col-sm-4">
            <label htmlFor="">Ingresa el incremento de cada ciclo <span className='units-lowercase'>[km]</span></label>
                <br />
                <input className="col-md-2 form-control" type="number" value={increment} min={1} onChange={handleIncrement} />
            </div>
            <div className="col-sm-1">
              <br />
            <button onClick={handleFillData}>Rellenar</button>
            <br />
            </div>
            <div className="col-sm-3">
              <br />
            {minToFill[0].mileage > 120000 ? <><button onClick={handleResetData}>Reiniciar a 120.000 km</button> <TooltipModal text={'La función reiniciar permite eliminar todos los mantenimientos agregados del vehículo a partir de los 120.000km.'} title={'Reiniciar datos de mantenimiento'} /></> : <></>}
            <br />
            <br />
            </div>
          </div>
          <br /><br />
          <br /><br />
 
          <div className="row">
              <div className="col-sm-3">
                <label htmlFor="">Número máximo de rutinas <span className='units-lowercase'>[km]</span></label>
                <br />
                <input className="col-md-2 form-control" type="number" value={maxValue} min={1} onChange={handleMaxValue} />
                <br />
              </div>
              <div className="col-sm-3">
                  <br />
                  <button onClick={handleFormSubmit}>Agregar Nueva Rutina</button> <TooltipModal text={'La función rellenar permite que se generen automáticamente valores de revisión hasta 700.000 km, tomando los últimos cuatro (4) valores ingresados, y generando iteraciones cada 5.000 km. Por ej: una vez ingresados los datos hasta 120.000 km, me aparece la opción de rellenar. Al darle clic, se me generan valores de kilometraje (cada 5.000 de incremento) hasta 700.000. Al tomar los últimos cuatro (4) valores, el precio que se ingresa a 125.000 km corresponde al que se ingresó en 105.000 km, el precio que se ingresa a 130.000 km corresponde al que se ingresó en 110.000 km, y así sucesivamente.'} title={'Rellenar datos de mantenimiento'} />
                  <br />
              </div>
            </div>
          <div className="col-sm-12">
            <table className="table">
              <thead>
                <tr>
                  <th>Precio [$ {vehicleData[0]["currency"]}] </th>
                  <th>Kilometraje [km]</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {maintenances.map((datas, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="number"
                        className='form-control'
                        onChange={(e) => handlePrice(index, e)}
                        value={datas.price}
                        disabled={!datas.editMode}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className='form-control'
                        onChange={(e) => handleMileage(index, e)}
                        value={datas.mileage.toLocaleString('es', options).replace(/,/g, '.')}
                        disabled={!datas.editMode}
                      />
                    </td>
                    <td className="col-options">
                      {datas.editMode ? (
                        <>
                          <button
                            className="btn btn-primary m-1"
                            onClick={() => handleUpdate(index)}
                          >
                            Actualizar
                          </button>
                          <button
                            className="btn btn-secondary m-1"
                            onClick={() => handleCancel(index)}
                          >
                            Cancelar
                          </button>
                        </>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleEdit(index)}
                        >
                          Editar
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ListMaintenances;
