import { useState, useEffect } from 'react';
import Spinner from '../../components/spinner/Spinner';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import TableAssurance from './components/TableAssurance';

const Assurance = () => {
    
const [cleanDataDetail, setCleanDataDetail] = useState([])
const [loading, setLoading] = useState(true)

useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("assurance"));
    if (savedData) {
      setCleanDataDetail(savedData);
      setLoading(false)
    } else {
      setCleanDataDetail([]);
      setLoading(false)
    }
  }, []);

return (
    <div>
        <TitleComponent text={'Seguros e impuestos'} />
        {loading ? <Spinner /> : <TableAssurance data={cleanDataDetail} />}
    </div>
)
}

export default Assurance;