import LoaderUtilsService2 from "../services/admin/utils/loaderUtilsService2";

const CustomLoader = async () => {
    const region = localStorage.getItem('region')
    try {
        const url = `custom/${region}`;
        const custom_data = await LoaderUtilsService2(url);

        localStorage.setItem("customizations", JSON.stringify(custom_data));
    } catch (error) {
        console.error(`Error al cargar las costomizaciones de ${region}`);
    }
}


export default CustomLoader